<template>
  <div>
    <div class="h-35 bg-white space-y-2 px-8 py-6">
      <p class="text-sm">
        <breadcrumbs />
      </p>
      <div class="flex flex-row justify-between">
        <h2 class="font-medium">
          {{ isLoading ? '' : organizationName }}
        </h2>
        <MergeButtonRound
          v-if="!standardUser"
          button-type="primary"
          @click="createNewSite(organizationId)"
        >
          <template #left-icon>
            <ic-plus
              direction="left"
              class="white"
            />
          </template>
          <p>Add Account</p>
        </MergeButtonRound>
      </div>
      <p class="text-sm text-bb-mid-grey">Organization Account</p>
    </div>

    <div class="overflow-y-auto base-scrollbar">
      <div class="mb-20 w-full">
        <bb-table
          class="px-8 py-6"
          :headers="headers"
          :data="organizationSiteRows"
          :is-loading="isLoading"
          :scroll-y="true"
          :sticky-header="true"
          :default-sorting-order="defaultSortingOrder"
          max-table-height="calc(100vh - 400px)"
        >
          <template #rows="{ tableData }">
            <site-rows
              :data="tableData"
              :organization="{ organizationId, organizationName }"
              @select-row="selectRow"
            />
          </template>
        </bb-table>
      </div>
    </div>

    <!--MODAL COMPONENTS-->
    <bb-base-modal
      v-if="formValues"
      :width="size.width"
      :height="size.height"
      :form-values="formValues"
      :is-modal-loading="isModalLoading"
      @close="closeModal"
    >
      <template #container="{ form, loadingStatus }">
        <edit-site-modal-form
          v-if="form.modal === 'edit-site'"
          :is-loading="loadingStatus"
          :selected-site="form.data"
          :organization="{ organizationId, organizationName }"
          @close="closeModal"
          @update-site="updateSite"
        />
        <remove-item-modal
          v-if="form.modal === 'remove-item'"
          :is-loading="loadingStatus"
          remove-button-text="Yes, remove account"
          @close="closeModal"
          @remove="removeSite"
        >
          <div>
            <h4 class="h4">Are you sure you want to remove this account?</h4>
          </div>
          <div class="mt-4">
            <p>This action cannot be undone.</p>
            <p class="mt-4">This action will:</p>
            <ul class="list-disc ml-6">
              <li>remove the Account from BrightBid</li>
              <li>stop all ongoing optimizations for the account</li>
            </ul>
            <p class="mt-4">
              <span class="font-bold">This action will not</span> remove or pause Account or Campaigns in Google Ads
            </p>
          </div>
        </remove-item-modal>
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import SiteRows from '@/components/table/table_rows/SiteRows.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import BbTable from '@/components/table/BbTable.vue'
import Toast from '@/components/shared/Toast.vue'
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import EditSiteModalForm from '@/components/modals/forms/EditSiteModalForm.vue'
import RemoveItemModal from '@/components/modals/forms/RemoveItemModal.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'

export default {
  name: 'SiteView',
  components: {
    EditSiteModalForm,
    BbBaseModal,
    Breadcrumbs,
    BbTable,
    SiteRows,
    MergeButtonRound,
    RemoveItemModal,
  },
  data() {
    return {
      defaultSortingOrder: { order: 'DESCENDING', column: 'site' },
      headers: [
        {
          value: 'site',
          label: 'Account',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'id',
          label: 'ID',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'isActive',
          label: 'Status',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'url',
          label: 'Url',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'channels',
          label: 'Channels',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'description',
          label: 'Business Description',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'target',
          label: 'B2B/B2C',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'actions',
          label: 'Actions',
          sortable: false,
          sorting: null,
          position: 'right',
        },
      ],
      organizationId: null,
      organizationName: null,
      isLoading: false,
      isModalLoading: false,
      formValues: null,
      size: null,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters({
      organizationSiteRows: 'site/organizationSiteRows',
      standardUser: 'auth/isStandardUser',
    }),
  },
  async mounted() {
    this.isLoading = true
    this.organizationId = this.$route.params.id
    const { data } = await this.$http.get(`common/organization/${this.organizationId}`)
    this.organizationName = data.name
    await this.getOrganizationSites()
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      loadOrganizationSiteList: 'site/loadOrganizationSiteList',
    }),
    async getOrganizationSites() {
      try {
        const { data } = await this.$http.get(`common/organization/${this.organizationId}/sites`)
        await this.loadOrganizationSiteList(data)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch the sites',
            type: 'error',
          },
        })
        return []
      }
    },
    closeModal() {
      this.formValues = null
    },
    selectRow(selectedItem) {
      this.formValues = selectedItem
      switch (this.formValues.modal) {
        case 'edit-site':
          this.size = { width: '450px', height: '500px' }
          break
        case 'remove-item':
          this.size = { width: '560px', height: '340px' }
          break
      }
    },
    createNewSite(organizationId) {
      this.$router.push({
        name: 'onboarding-wizard',
        query: {
          desiredState: 'SETUP_CONNECTIONS',
          organizationId,
        },
      })
    },
    async updateSite(payload) {
      try {
        this.isModalLoading = true

        await this.$http.patch(`common/site/${payload.id}/basic-info`, {
          name: payload.site,
          description: payload.description,
        })

        this.closeModal()

        this.isLoading = true
        await this.getOrganizationSites()
        this.isLoading = false
      } catch (error) {
        console.log(error)
      } finally {
        this.isModalLoading = false
      }
    },
    async removeSite() {
      try {
        this.isModalLoading = true

        await this.$http.delete(`common/site/${this.formValues.data.id}`)

        this.closeModal()

        this.isLoading = true
        await this.getOrganizationSites()

        this.isLoading = false
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Could not remove the site. Please try again later.',
            type: 'error',
          },
        })
      } finally {
        this.isModalLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.table-container {
  overflow-y: auto;
  height: calc(100vh - 243px);
}
</style>
